// ----- Font utilities -----
$font-sizes: (
  5,
  6,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  22,
  24,
  50
);
$font-weights: (400, 500, 600, 700);

@each $size in $font-sizes {
  .u-fs-#{$size} {
    font-size: #{$size}px;
  }
}
@each $weight in $font-weights {
  .u-fw-#{$weight} {
    font-weight: $weight;
  }
}
.font-bold {
  font-weight: bold;
}

// ----- Utility classes -----
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.text-capital {
  text-transform: capitalize !important;
}
.text-white {
  color: #fff !important;
}
.text-grey {
  color: grey !important;
}
.text-red {
  color: red !important;
}
.text-primary {
  color: #00aeef !important;
}
.text-grey2 {
  color: #6d8494 !important;
}
.text-green {
  color: #00a651 !important;
}
.color-initial {
  color: initial !important;
}
.color-unset {
  color: unset !important;
}
.text-underline {
  text-decoration: underline !important;
}
.flex-center {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}
.flex-wrap {
  flex-wrap: wrap;
}
.cursor-pointer {
  cursor: pointer !important;
}
.pointer-none {
  pointer-events: none !important;
}
.pointer-auto {
  pointer-events: auto !important;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.d-block {
  display: block !important;
}
.d-none {
  display: none !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-flex {
  display: flex;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-evenly {
  justify-content: space-evenly;
}
.justify-content-around {
  justify-content: space-around;
}
.justify-content-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}
.align-items-stretch {
  align-items: stretch;
}
.align-items-start {
  align-items: flex-start;
}
.align-items-end {
  align-items: flex-end;
}
.flex-grow {
  flex-grow: 1;
}
.flex-col {
  flex-direction: column !important;
}
.flex-row {
  flex-direction: row;
}
.row-reverse {
  flex-direction: row-reverse;
}
.col-reverse {
  flex-direction: column-reverse;
}
.outline-none {
  outline: none !important;
}
.u-w-0 {
  width: 0 !important;
}
.u-h-0 {
  height: 0 !important;
}
.u-w-full {
  width: 100% !important;
}
.u-h-full {
  height: 100% !important;
}
.u-w-30 {
  width: 30% !important;
}
.u-w-50 {
  width: 50% !important;
}
.u-w-90 {
  width: 90% !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.border-none {
  border: none !important;
}
.u-p-0 {
  padding: 0 !important;
}
.u-m-0 {
  margin: 0 !important;
}
.u-pb-0 {
  padding-bottom: 0 !important;
}
.u-mt-30 {
  margin-top: 30px !important;
}
.u-pl-30 {
  padding-left: 30px !important;
}
.u-mt-10 {
  margin-top: 10px !important;
}
.u-mb-10 {
  margin-bottom: 10px !important;
}
.u-ml-10 {
  margin-left: 10px !important;
}
.u-mr-10 {
  margin-right: 10px !important;
}
.u-mb-22 {
  margin-bottom: 22px !important;
}
.u-pt-5 {
  padding-top: 5px !important;
}
.u-pl-5 {
  padding-left: 5px !important;
}
.m-auto {
  margin: auto !important;
}
.u-bg-primary {
  background-color: #00aeef !important;
}
.u-bg-white {
  background-color: #fff !important;
}
.u-bg-lightblue {
  background-color: #b2e4f5 !important;
}
.u-bg-lightgrey {
  background-color: #e6eaed !important;
}
.u-gap-5 {
  gap: 5px !important;
}
.u-gap-10 {
  gap: 10px !important;
}
#airline_logos_airkbzmingalar {
  width: 90px;
  height: 45px;
}
#airline_logos_cebuPacific {
  width: 65px;
  height: 27px;
}
.checkbox-primary {
  color: #00aeef !important;
}
.checkbox-common {
  width: 15px !important;
  height: 15px !important;
}
.opacity-0 {
  opacity: 0 !important;
}
.opacity-1 {
  opacity: 1 !important;
}
.opacity-5 {
  opacity: 0.5 !important;
}
.opacity-7 {
  opacity: 0.7 !important;
}
.u-top-76 {
  top: 76%;
}
.u-left-74 {
  left: 74%;
}
.u-pl-10 {
  padding-left: 10px !important;
}
